import React from 'react'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import Content from '../components/Content'
import Layout from '../components/Layout'
import './ContactPage.css'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({ body }) => (
  <main className="Contact">
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transform={{ duration: 1 }}
    >
      <section className="Contact--Section1 row">
        <div className="Contact--BG col-lg-6">
          <div className="Contact--Logo--Container my-auto mx-auto">
            <img
              src="/images/mms-w.svg"
              className="img-fluid my-auto taCenter"
              alt="Mass Marketing Services"
            />
          </div>
        </div>
        <div className="Contact--Card col-lg-6">
          <div className="container my-auto">
            <div className="Contact--Details section">
              <Content source={body} />
            </div>
            <form
              name="contact"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              className="col-lg-10"
            >
              <input type="hidden" name="form-name" value="contact" />
              <div className="py-3">
                <label className="Form--Label">
                  <input
                    className="Form--Input Form--InputText"
                    type="text"
                    placeholder="Name*"
                    name="firstname"
                    required
                  />
                  <span>Name</span>
                </label>
              </div>

              <div className="py-3">
                <label className="Form--Label">
                  <input
                    className="Form--Input Form--InputText"
                    type="email"
                    placeholder="Email*"
                    name="emailAddress"
                    required
                  />
                  <span>your@email.com</span>
                </label>
              </div>

              <div className="py-3">
                <label className="Form--Label">
                  <input
                    className="Form--Input Form--InputText"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    placeholder="Phone"
                    maxLength="11"
                    name="phone"
                    type="tel"
                    required
                  />
                  <span>### ### ####</span>
                </label>
              </div>

              <div className="py-3">
                <label className="Form--Label">
                  <textarea
                    className="Form--Input Form--InputText"
                    type="text"
                    placeholder="Message"
                    name="message"
                    required
                    rows="4"
                  />
                  <span>Message</span>
                </label>
              </div>
              <p>
                <button
                  type="submit"
                  className="Button Form--SubmitButton"
                  aria-label="Contact Mass Marketing Services"
                >
                  Contact Us
                </button>
              </p>
            </form>
          </div>
        </div>
      </section>
    </motion.div>
  </main>
)

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
      }
    }
  }
`
